<template>
  <div class="px-2">
    <b-tabs
      v-if="isTabs"
      active-tab-class="p-0 "
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <b-tab
        lazy
        title="Pending"
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
      >
        <LoansTable :tab="tab" :status="1" />
      </b-tab>
      <b-tab lazy :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
        <template #title>
          Completed
          <span
            class="ml-1"
            v-if="
              counterTab.counter_comp > 0 && isManagement && !isExternalLoan
            "
          >
            <feather-icon
              :badge="
                counterTab.counter_comp > 99 ? '99+' : counterTab.counter_comp
              "
              badge-classes="badge-danger badge-glow"
            />
          </span>
        </template>
        <LoansTable :tab="tab" :status="2" />
      </b-tab>

      <b-tab
        lazy
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        v-if="moduleId !== 4"
      >
        <template #title>
          Finished
          <span
            class="ml-1"
            v-if="
              counterTab.counter_comp > 0 && isManagement && !isExternalLoan
            "
          >
          </span>
        </template>
        <LoansTable :tab="tab" :status="3" />
      </b-tab>
    </b-tabs>
    <LoansTable v-if="!isTabs" :tab="tab" :status="2" />
  </div>
</template>

<script>
import LoansTable from "@/views/commons/components/loans/LoansTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "LoansGeneral",
  components: {
    LoansTable,
  },
  props: {},
  data() {
    return {
      tab: this.$route.meta.tab,
    };
  },
  mounted() {},
  computed: {
    isTabs() {
      return this.$route.meta.tab === 2 || this.$route.meta.tab === 3;
    },
    isManagement() {
      return this.moduleId == 16;
    },
    isExternalLoan() {
      return this.$route.meta.type_tab == 2;
    },
    ...mapGetters({ counterTab: "loans-store/counterTab" }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {},
};
</script>

<style>
</style>